<template>
  <v-container fluid class="pt-0">
    <v-row class="pt-2 px-4">
      <v-col cols="12" class="pb-1">
        <MonthComponent
            v-model="date_start"
            @onMonthChange="monthChange(false)"
            :depressed="false"
            :label="$vuetify.breakpoint.smAndUp ? 'Період з' : 'Період із '"
            :label_style="monthComponentStyle.label"
            :button_style="monthComponentStyle.button"
            :class_="$vuetify.breakpoint.xs ? 'white mr-2 mb-2' : 'white mr-2'"
            :block="$vuetify.breakpoint.xsOnly"
            :width="230"
        />
        <MonthComponent
            v-model="date_end"
            @onMonthChange="monthChangeEnd"
            :depressed="false"
            :label="$vuetify.breakpoint.smAndUp ? 'по' : 'Період по'"
            :label_style="monthComponentStyle.label"
            :button_style="monthComponentStyle.button"
            :class_="$vuetify.breakpoint.xs ? 'white' : 'white'"
            :block="$vuetify.breakpoint.xsOnly"
            :width="230"
        />
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="d-flex pa-3">
          <div style="flex: 0 0 86px">
            <v-icon size="64" color="teal">mdi-currency-usd</v-icon>
          </div>
          <div style="flex: 1" class="main-card-content">
            <div class="main-card-title text-no-wrap">Залишок коштів</div>
            <div class="main-card-value text-no-wrap">{{ end_balance }} грн.</div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="d-flex pa-3">
          <div style="flex: 0 0 86px">
            <v-icon size="64" color="success">mdi-arrow-down-bold-hexagon-outline</v-icon>
          </div>
          <div style="flex: 1" class="main-card-content">
            <div class="main-card-title">Надходження</div>
            <div class="main-card-value">{{ income }} грн.</div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" sm="6" md="4">
        <v-card class="d-flex pa-3">
          <div style="flex: 0 0 86px">
            <v-icon size="64" color="secondary lighten-1">mdi-arrow-up-bold-hexagon-outline</v-icon>
          </div>
          <div style="flex: 1" class="main-card-content">
            <div class="main-card-title">Витрата</div>
            <div class="main-card-value">{{ outcome }} грн.</div>
          </div>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="px-4">
      <v-col cols="12">
        <v-tabs class="custom-tabs" color="success">
          <v-tab class="text-left justify-start">
            Деталізація
          </v-tab>
          <v-tab class="text-left justify-start">
            Статистика
          </v-tab>

          <v-tab-item class="pa-0">
            <v-row>
              <v-col cols="12">
                <v-card
                    v-for="item in items" :key="item.idx" tile elevation="2"
                    class="mb-1 d-flex flex-wrap flex-row row-card pa-1"
                    :class="item.idx === 0 ? 'mt-2' : ''"
                    :style="item.income ? 'border-left: 4px solid #5bb55f' : 'border-left: 4px solid #f18a0d'"
                >
                  <div style="flex: 0 0 50px; display: flex; align-items: center; justify-content: center">
                    <v-icon size="26" :color="item.income ? 'success' : 'secondary'">
                      {{ item.income ? 'mdi-arrow-down-bold-hexagon-outline' : 'mdi-arrow-up-bold-hexagon-outline' }}
                    </v-icon>
                  </div>
                  <div style="flex: 0 0 120px;" class="pa-2">
                    <div class="col-value">{{ item.date | formatDate }}</div>
                  </div>
                  <div style="flex: 0 0 360px;" class="pa-2">
                    <div class="col-value">{{ item.cash_flow_item_name }}</div>
                  </div>
                  <div style="flex: 0 0 180px;" class="px-2 py-1">
                    <div
                        class="col-value font-weight-medium"
                    >
                      <v-chip small>
                        {{ item.income ? 'Надходження' : 'Витрата' }}
                      </v-chip>
                    </div>
                  </div>
                  <div style="flex: 0 0 300px;" class="pa-2">
                    <div class="col-value">{{ item.bank_account_name}}</div>
                  </div>
                  <div style="flex: 0 0 280px;" class="pa-2">
                    <div class="col-value font-weight-medium">{{ item.sum | formatToFixed | formatNumber}}</div>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item class="pa-0">
            <v-row>
              <v-col cols="12" md="6" sm="12" class="pt-6">
                <div class="mt-1 mb-2" style="font-size: 1.2rem; font-weight: 500">
                  <v-chip label>
                    Надходження на рахунок(-ки)
                  </v-chip>
                </div>
                <v-card class="pa-4 mb-3"
                        v-for="(item, idx) in income_statistic"
                        :key="`income-${idx}`"
                        elevation="3"
                >
                  <div
                  >
                    <div class="mb-2 d-flex align-center">
                      <v-icon size="28" class="mr-2"
                              :color="getIconForCashFlow(item.cash_flow_item_name, 'color')"
                      >{{ getIconForCashFlow(item.cash_flow_item_name) }}</v-icon>
                      {{ item.cash_flow_item_name }} (<strong>{{item.percent}}%</strong>)
                    </div>
                    <v-progress-linear
                        :value="item.percent"
                        color="grey lighten-2"
                        height="30"
                    >
                      <template v-slot:default="">
                        <strong style="color: #424242">{{ item.sum }} грн.</strong>
                      </template>
                    </v-progress-linear>
                  </div>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="12" class="pt-6">
                <div class="mt-1 mb-2" style="font-size: 1.2rem; font-weight: 500">
                  <v-chip label>
                    Витрата із рахунка(-ків)
                  </v-chip>
                </div>
                <v-card class="pa-4 mb-3"
                        v-for="(item, idx) in outcome_statistic"
                        :key="`outcome-${idx}`"
                        elevation="3"
                >
                  <div
                  >
                    <div class="mb-2 d-flex align-center">
                      <v-icon size="28" class="mr-2"
                              :color="getIconForCashFlow(item.cash_flow_item_name, 'color')"
                      >{{ getIconForCashFlow(item.cash_flow_item_name) }}</v-icon>
                      {{ item.cash_flow_item_name }} (<strong>{{item.percent}}%</strong>)
                    </div>
                    <v-progress-linear
                        :value="item.percent"
                        color="teal lighten-3"
                        height="30"
                    >
                      <template v-slot:default="">
                        <strong style="color: #424242">{{ item.sum }} грн.</strong>
                      </template>
                    </v-progress-linear>
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import {getCurrentDate, beginOfMonth, endOfMonth} from "@/utils/icons";
import financialStatsAPI from "@/utils/axios/financial_stats"

export default {
  name: "financial_stats",
  props: {
    personal: {
      type: Boolean,
      default: false
    },
    person_hash: {
      type: String,
      default: ''
    }
  },
  components: {
    MonthComponent: () => import("@/components/MonthComponent")
  },
  computed: {
    monthComponentStyle() {
      if (this.$vuetify.breakpoint.smAndUp) {
        return {
          label: 'color: var(--v-success-darken1) !important; margin-right: 4px;',
          button: 'padding-right: 9px !important; padding-left: 9px !important;'
        }
      } else {
        return {
          label: 'color: var(--v-success-darken1) !important; margin-right: 4px;',
          button: 'padding-right: 9px !important; padding-left: 9px !important; justify-content: flex-start;'
        }
      }
    }
  },
  data() {
    return {
      windowSize: {
        x: 0,
        y: 0,
      },
      date_start: null,
      date_end: null,
      start_balance: null,
      end_balance: null,
      income: null,
      outcome: null,
      items: [],
      groups: [],
      colors: [
      'rgb(73,165,227)',
      'rgb(47,156,236)',
      'rgb(112,133,236)',
      'rgb(123,121,212)',
      'rgb(170,65,238)',
      'rgb(215,92,200)',
      'rgb(224,70,142)',
      'rgb(206,98,65)',
      'rgb(205,139,59)',
      'rgb(108,167,71)',
      'rgb(131,215,79)',
      'rgb(131,243,61)',
      'rgb(98,198,22)',
      'rgb(54,235,63)',
      'rgb(222,31,181)'
    ],
      income_statistic: [],
      outcome_statistic: []
    }
  },
  methods: {
    monthChange(change_date_end=true) {
      if (this.date_start) {
        const new_date_end = endOfMonth(this.date_start)
        if (this.date_end !== new_date_end) {
          this.clear = true
          this.report_data_loaded = false
        }
        if (change_date_end) {
          this.date_end = new_date_end
        }
      }
      if (this.date_start && this.date_end) {
        this.getStartData()
      }
    },
    monthChangeEnd() {
      if (this.date_end) {
        this.date_end = endOfMonth(this.date_end)
      }
      if (this.date_start && this.date_end) {
        this.getStartData()
      }
    },
    getStartData() {
      financialStatsAPI.all({date_start: this.date_start, date_end: this.date_end})
        .then(response => response.data)
        .then(data => {
          let income = 0
          let outcome = 0
          const groups = []
          data.journal.forEach(i => {
            const find = groups.find(g => g.cash_flow_item_name === i.cash_flow_item_name)
            if (!find) {
              groups.push({
                cash_flow_item_name: i.cash_flow_item_name,
                sum: i.sum
              })
            } else {
              find.sum += i.sum
            }

            if (i.income) {
              income += i.sum
            } else {
              outcome += i.sum
            }
          })
          this.start_balance = data.balance.start || 0
          this.end_balance = data.balance.end || 0
          this.items = data.journal.map((item, idx) => Object.assign({}, item, {idx: idx}))
          this.income = income
          this.outcome = outcome
          this.groups = groups

          this.income_statistic = this.getChartGroupData(true)
          this.outcome_statistic = this.getChartGroupData(false)
        })
    },
    getChartGroupData(income) {
      const items = []
      let total = 0
      const new_items = this.items.filter(c => c.income === income).sort(((a, b) => b.sum - a.sum))
      new_items.forEach(b => {
        const find = items.find(c => c.cash_flow_item_name === b.cash_flow_item_name)
        total += b.sum
        if (find) {
          find.sum += b.sum
        } else {
          items.push(
              {
                cash_flow_item_name: b.cash_flow_item_name,
                sum: b.sum
              }
          )
        }
      })
      return items.map(i => {
        return {...i, percent: (i.sum / total * 100).toFixed(2)}
      })
    },
    getIconForCashFlow(text, field='text') {
      const find = []
      const icons = {
        'mdi-account-cash-outline': ['зарплата', 'зарп', 'зп.', 'з/п'],
        'mdi-star-shooting-outline': ['податки', 'податок', 'єсв', 'військовий', 'збір'],
        'mdi-cart-arrow-up': ['постачальник', 'постачальниками', 'продаж'],
        'mdi-cart-arrow-down': ['покупець', 'покупцями', 'закупки', 'закупівля'],
        'mdi-account-tie-outline': ['адмін', 'адміністративні'],
        'mdi-toolbox-outline': ['послуги', 'послуга'],
        'mdi-gift-open-outline': ['товар', 'товари'],
        'mdi-bank-outline': ['касове', 'комісія', 'банківські'],
        'mdi-piggy-bank-outline': ['депозит', 'відсотки', 'відсотки'],
        'mdi-domain': ['оплата від мешканців', 'оплата', 'мешканців', 'співвласників']
      }
      const colors = {
        'mdi-account-cash-outline': 'success',
        'mdi-star-shooting-outline': 'primary',
        'mdi-cart-arrow-up': 'teal',
        'mdi-cart-arrow-down': 'secondary',
        'mdi-account-tie-outline': 'purple',
        'mdi-toolbox-outline': 'pink',
        'mdi-gift-open-outline': 'blue',
        'mdi-bank-outline': 'error',
        'mdi-piggy-bank-outline': 'success',
        'mdi-domain': 'teal',
        'mdi-account': 'error lighten-1'
      }

      Object.keys(icons).forEach(i => {
        icons[i].forEach(b => {
          if (text.toLowerCase().indexOf(b.toLowerCase()) !== -1) {
            find.push(i)
          }
        })
      })

      const item = {text: find.length > 0 ? find[0] : 'mdi-account', color: colors[find.length > 0 ? find[0] : 'mdi-account'] }

      return item[field]
    }
  },
  watch: {
    person_hash: {
      immediate: true,
      handler(payload) {
        if (payload) {
          this.getStartData()
        }
      }
    },
  },
  created() {
    this.date_start = beginOfMonth(getCurrentDate())
    this.date_end = endOfMonth(getCurrentDate())

    this.getStartData()
  }
}
</script>

<style scoped lang="scss">
.main-card-content {
  .main-card-title {
    font-size: 1.2rem;
    color: #3d3d3d;
  }
  .main-card-value {
    font-size: 1.54rem;
    font-weight: 500;
    color: #5e5e5e;
  }
}
.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }
}
.col-title {
  font-size: .77rem;
  color: #656565;
}
.col-value {
  font-size: .82rem;
  color: #3d3d3d;
}
</style>